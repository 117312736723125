import React, { createContext, useState, useContext } from 'react';

const AccessGroupContext = createContext();

export const AccessGroupProvider = ({ children }) => {
    const [accessGroups, setAccessGroups] = useState([]);

    return (
        <AccessGroupContext.Provider value={{ accessGroups, setAccessGroups }}>
            {children}
        </AccessGroupContext.Provider>
    );
};

export const useAccessGroup = () => {
    const context = useContext(AccessGroupContext);
    if (context === undefined) {
        throw new Error('useAccessGroup must be used within a AccessGroupProvider');
    }
    return context;
};
