import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useTable, useFilters, useGlobalFilter, useRowSelect, usePagination, useSortBy } from 'react-table';
import { GlobalFilter, DefaultFilterForColumn } from "./CustomFilter";
import { Container, Dropdown, Toast } from 'react-bootstrap';
import { BsArrowDown, BsArrowUp, BsArrowDownUp, BsLaptop } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { RiUserSearchFill } from "react-icons/ri"
import { useCookies } from "react-cookie";
import { apiUrl } from '../../config.js';
import { intialfiltervalues, intialtwofiltervalues } from './FilterInitialstate';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { BsFillFunnelFill } from "react-icons/bs";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import DropdownMenu from '../../../node_modules/react-bootstrap/esm/DropdownMenu';
import Modal from 'react-bootstrap/Modal';
import DropdownButton from '../../../node_modules/react-bootstrap/esm/DropdownButton';
import Col, { useCol } from '../../../node_modules/react-bootstrap/esm/Col';
import FilterPill from '../FilterPill-Component/FilterPill';

// Our table component
export function Table({ columns, data, skipPageReset, updateMyData, Topfilter, Companyfilter, Savedfilter, Filtervalues, onGlobalFilterChange, Externalvalue, exportingacess, handeladvanceserch, handelonupdate, combinedfilter, handelbulkupdate, accessGroups }) {
    const [selectedOption, setSelectedOption] = useState([]);
    const [Staffids, setStaffids] = useState([]);
    const [Rowdata, setRowdata] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columns.map((col) => col.id));

    const [cookies] = useCookies(["jwt"]);
    const jwt = cookies.jwt;
    const [showToast, setShowToast] = useState(false);
    const [ToastMessage, setToastMessage] = useState('');
    const [filtersToapply, setfiltersToapply] = useState(intialfiltervalues);
    const [filteraccordion, setfilteraccordion] = useState(false);
    const scrollrefernce = useRef(null);
    const UserId = cookies['userid'];
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {

        //while applying filter from saved filter it we are captilizing the names for filter,
        //here we are converting into same values as intitalfiltervalues
        const initialFilterValuesKeys = Object.keys(filtersToapply);
        const lowerCaseAnotherObject = {};
        for (let key in Filtervalues) {
            lowerCaseAnotherObject[key.toLowerCase()] = Filtervalues[key];
        }
        const matchingKeyValuePairs = {};
        initialFilterValuesKeys.forEach(key => {
            let lowerCaseKey = key.toLowerCase();
            if (lowerCaseAnotherObject.hasOwnProperty(lowerCaseKey)) {
                matchingKeyValuePairs[key] = lowerCaseAnotherObject[lowerCaseKey];
            }
        });
        const falseProperties = Object.entries(matchingKeyValuePairs)
            .filter(([key, value]) => value === false)
            .map(([key, value]) => key);
        //const trueProperties = Object.entries(Filtervalues)
        //    .filter(([key, value]) => value === true)
        //    .map(([key, value]) => key);
        setHiddenColumns([...falseProperties, falseProperties]);

    }, [Filtervalues]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        toggleAllRowsSelected,
        prepareRow,
        allColumns,
        setGlobalFilter,
        preGlobalFilteredRows,
        selectedFlatRows,
        setAllFilters,
        toggleRowSelected,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        rows,


    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pagesize: 10, hiddenColumns: hiddenColumns },
            defaultColumn: { Filter: DefaultFilterForColumn },
            autoResetPage: !skipPageReset,
            updateMyData,
            Topfilter,
            Filtervalues


        },

        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [

                {
                    id: 'selection',
                    accessor: 'select',
                    Header: ({ getToggleAllRowsSelectedProps }) => {
                        const toggleProps = getToggleAllRowsSelectedProps();
                        return (
                            <div>
                                <input
                                    type="checkbox"
                                    checked={toggleProps.checked}
                                    indeterminate={toggleProps.indeterminate}
                                    onChange={(e) => {
                                        toggleAllRowsSelected(!toggleProps.checked);
                                        handleAllRowsSelected(e.target.checked);
                                    }}
                                />
                            </div>
                        );
                    },
                    Cell: ({ row }) => {
                        const isChecked = row.isSelected;
                        return (
                            <div>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={(e) => {
                                        toggleRowSelected(row.id);
                                        handleRowSelected(row, e.target.checked);
                                    }}
                                />
                            </div>
                        );
                    }
                },
                ...columns,
            ])
        }

    );




    useEffect(() => {
        if (scrollrefernce.current) {
            scrollrefernce.current.scrollTop = 0;
        }
    }, [pageIndex]);


    useEffect(() => {
        setRowdata([]);
        setStaffids([]);
    }, [data]);


    const handleRowSelected = (row, isChecked) => {

        if (isChecked) {
            setStaffids((prevStaffids) => [...prevStaffids, row.original.staffID]);
            setRowdata((prev) => [...prev, row.original]);
        } else {
            setStaffids((prevStaffids) =>
                prevStaffids.filter((staffID) => staffID !== row.original.staffID));

            setRowdata((prevdata) => prevdata.filter((rowdata) => rowdata !== row.original));
        }
    };
    const handleAllRowsSelected = (isSelected) => {
        if (isSelected) {
            setStaffids(rows.map((row) => row.original.staffID));
            setRowdata(rows.map((row) => row.original));
        } else {
            setStaffids([]);
            setRowdata([]);
        }
    };

    const handelactions = (event) => {
        const selectedOption = event;
        setSelectedOption(selectedOption || undefined);

        if (selectedOption === 'opt5') {
            handeladvanceserch();
            return;
        }

        if (Staffids.length === 0) {
            window.alert('Please select the check box in the table');
        }
        else if (Staffids !== null || Staffids === []) {
            switch (selectedOption) {
                case 'opt1':
                    chaseinfo(Staffids);
                    break;
                case 'opt2':
                    chaserefences(Staffids);
                    break;
                case 'opt3':
                    sendmessage(Staffids);
                    break;
                case 'opt4':
                    generateexcel(Staffids);
                    break;
                case 'opt6':
                    BulkUpdatestaffInfo(Rowdata);
                    break;
                default:
                    console.log('invalid');
                    break;
            }
        }

    };
    //To close the filters dropdown once filter is applied
    const handleToggle = () => setIsOpen(prev => !prev);
    return (
        <>


            <>

                <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                    style={{
                        position: 'fixed',
                        top: 135,
                        right: 20,
                        zIndex: 999,
                        minWidth: 200,
                        backgroundColor: 'rgba(76, 175, 80, 0.9)',

                    }}
                >
                    <Toast.Header>
                        <strong className="mr-auto">Success</strong>
                    </Toast.Header>
                    <Toast.Body >{ToastMessage}</Toast.Body>
                </Toast>
            </>

            <div className="w-100 d-flex flex-wrap align-items-center justify-content-between filterbar-container">
                <FilterPill Appliedfilter={combinedfilter} onUpdate={handelonupdate} />
                <div className="search-container d-flex align-items-center">
                    <div className={`filter-container ${filteraccordion ? "filter-container-laptop" : ""}`}>
                        <Companyfilter />
                    </div>
                    <div className="live-search">
                        <GlobalFilter
                            Externalvalue={Externalvalue}
                            onGlobalFilterChange={onGlobalFilterChange}
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>

                    <div className="actions d-flex align-items-center">
                        <Dropdown title="Actions" onSelect={handelactions}>
                            <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2">
                                Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                           
                                {accessGroups.length > 0 && accessGroups[0].newSettingCanSendNotifications && <Dropdown.Item eventKey="opt1">Chase outstanding info from staff
                                </Dropdown.Item>}
                                {accessGroups.length > 0 && accessGroups[0].newSettingCanSendNotifications && <Dropdown.Item eventKey="opt2">Chase outstanding references
                                </Dropdown.Item>}
                                <Dropdown.Item eventKey="opt3">Send message to selected
                                </Dropdown.Item>
                                {exportingacess && <Dropdown.Item eventKey="opt4">Export to excel
                                </Dropdown.Item>}
                                <Dropdown.Item eventKey="opt5">Advanced staff search
                                </Dropdown.Item>
                                {exportingacess && <Dropdown.Item eventKey="opt6">Bulk update
                                </Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="saved-filters mr-1 d-flex align-items-center">
                        <Savedfilter />
                    </div>
                    <div className="filters-dropdown mr-1">
                        <Dropdown title="Filters" autoClose="outside" show={isOpen} onToggle={handleToggle}>
                            <Dropdown.Toggle variant="light" id="dropdown-split-basic" className="mr-2 filters-nav" type="button" >
                                <BsFillFunnelFill />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Topfilter onhide={handleToggle} />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

            </div>

            <div className="container-table d-flex justify-content-center">
                <div className="custom-table">

                    <div className={` d-flex ${filteraccordion ? "gap-5 ml-5" : "gap-3 mb-4"} `} >



                        <div className={"Filter_Header"}
                            style={filteraccordion ? { left: 'unset', top: '4em' } : {}}>

                        </div>

                    </div>

                    <div className={`custom-table-head ${data.length > 0 ? "custom-table-body " : "d-flex custom-table-body-empty"}`} ref={scrollrefernce}
                        style={filteraccordion ? { height: '60vh' } : { height: '72vh' }}>

                        <table {...getTableProps()}>
                            <thead className="main-tableheader">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => {
                                            const nonsortablecolumns = ['selection']
                                            const isSortable = !nonsortablecolumns.includes(column.id);
                                            return (
                                                <th {...column.getHeaderProps(isSortable ? column.getSortByToggleProps() : {})} data-column-name={column.id}>
                                                    <div className="header-svg-container">
                                                        {column.render('Header')}

                                                        {isSortable
                                                            &&
                                                            (column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <BsArrowDown />
                                                                    : <BsArrowUp />
                                                                : <BsArrowDownUp />
                                                            )
                                                        }

                                                    </div>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            {data.length > 0 ? (
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);

                                        return (
                                            <tr {...row.getRowProps()}>

                                                {row.cells.map((cell) => {
                                                    const header = cell.column.id;
                                                    return <td {...cell.getCellProps()} data-column-name={cell.column.id}>{cell.render("Cell")}</td>;
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>

                            ) : (
                                <div className="custom-message">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div>
                                            <BsLaptop className="custom-icon" />
                                            <VscGraph className="custom-icon mx-3" />
                                            <RiUserSearchFill className="custom-icon" />
                                        </div>

                                    </div>
                                    <h3><strong>Start your search!</strong></h3>
                                    <p>Use the filter button <BsFillFunnelFill /> at the top right to get started.</p>
                                </div>
                            )}
                        </table>
                    </div>


                </div>
            </div>

            <div className="pagination">
                <button className="pagination-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button className="pagination-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button className="pagination-button" onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button className="pagination-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );


    async function chaseinfo(Sids) {
        selectedFlatRows.map((v) => {
            toggleRowSelected(v.id, false);
        });
        try {
            const response = await fetch(`${apiUrl}/screeningsummary/ChaseStaffForInfo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${jwt}`,
                },
                body: JSON.stringify(Sids.join(',')),
            });
            if (response.ok && response.status === 200) {
                setToastMessage('Request sent successfully');
            } else {
                const results = await response.json();
                window.alert(results.value);
                setToastMessage('Error while sending the request');
            }
        } catch (error) {
            console.error('Error:', error);
            setToastMessage('An error occurred');
        }
        finally {
            setShowToast(true);
            setStaffids([]);
        }
    }






    async function chaserefences(Sids) {
        selectedFlatRows.map(v => {
            toggleRowSelected(v.id, false)
        });
        try {
            const response = await fetch(`${apiUrl}/screeningsummary/Chasereference`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${jwt}`,
                },
                body: JSON.stringify(Sids.join(","))
            });
            if (response.ok && response.status === 200) {
                setToastMessage('Request sent successfully');
            } else {
                const results = await response.json();
                window.alert(results.value);
                setToastMessage('Error while sending the request');
            }
        } catch (error) {
            console.error('Error:', error);
            setToastMessage('An error occurred');
        }
        finally {
            setShowToast(true);
            setStaffids([]);
        }
    }


    function sendmessage(Sids) {
        if (Sids.length > 0) {
            window.open(`${apiUrl}/v1/Broadcast_Message.aspx?ids=${Sids.join(",")}`, '_blank')

        }
        selectedFlatRows.map(v => {
            toggleRowSelected(v.id, false)
        });
        setStaffids([]);
    }
    async function generateexcel(Sids) {
        selectedFlatRows.map(v => {
            toggleRowSelected(v.id, false)
        });
        setToastMessage('Request sent successfully');
        try {
            const response = await fetch(`${apiUrl}/screeningsummary/ExportExcel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${jwt}`,
                },
                body: JSON.stringify(Sids.join(","))
            });
            const results = await response.json();
            const link = results.value.link;

            if (response.ok && response.status === 200) {


                const anchor = document.createElement('a');
                anchor.href = link;
                anchor.target = '_blank';                              
                anchor.rel = 'noopener';               
                document.body.appendChild(anchor);              
                anchor.click();                             
                document.body.removeChild(anchor);


            } else {

                window.alert(results.value);
                setToastMessage('Error while sending the request');
            }
        } catch (error) {
            console.error('Error:', error);
            setToastMessage('An error occurred');
        }
        finally {
            setShowToast(true);

        }

    }

    function BulkUpdatestaffInfo(staffids) {
        handelbulkupdate(staffids);
    }

}
export default Table;






