import React, { createContext, useState, useContext } from 'react';

const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ children }) => {
    const [featureFlags, setFeatureFlags] = useState([]);

    return (
        <FeatureFlagContext.Provider value={{ featureFlags, setFeatureFlags }}>
            {children}
        </FeatureFlagContext.Provider>
    );
};

export const useFeatureFlag = () => {
    const context = useContext(FeatureFlagContext);
    if (context === undefined) {
        throw new Error('useFeatureFlag must be used within a FeatureFlagProvider');
    }
    return context;
};
