import React from "react";
import "./Loading.scss";
import loadingArtwork from '../../images/C247Logo2023.gif';



const LoadingSpinner = ({ loading }) => {
    return loading ?
        <div className="modal-loading">
            <img src={loadingArtwork} />
        </div > : null;
};

export default LoadingSpinner;