import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Login from "./components/Login";
import Screeningtable from "./components/Screening-Component/ScreeningSummary";
//import EventControl from "./components/EventControl-Component/EventControl";
//import ManagerReport from "./components/StongegateReport-Component/ManagerReport";
//import Reports from "./components/Reports-Component/Reports";
//import ReportSingle from "./components/Reports-Component/ReportSingle";
//import Table from "./components/Screening-Component/TableMaster";


const AppRoutes = [
    {
        index: true,
        element: <Login />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/screeningsummary',
        element: <Screeningtable />
    },
    //{
    //    path: '/eventcontrol',
    //    element: <EventControl />
    //},
];

export default AppRoutes;
