// CustomInput.js
import React from 'react';
import './CustomInput.css';

const CustomInput = ({ label, iconStart, iconEnd, ...props }) => (
    <div className="custom-container">
        <label className="custom-label" htmlFor="customInput">
            {label}
        </label>
        {iconStart && (
            <div className="icon-start">
                <img src={iconStart} style={{ width: 20, height: 20 }} />
            </div>
        )}
        <input className="form-control custom-outline" id="customInput" {...props} />
        {iconEnd && (
            <div className="icon-end">
               {iconEnd} 
            </div>
        )}
    </div>
);

export default CustomInput;