import React, { Fragment } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { AccessGroupProvider } from './AccessGroupContext';
/*import ManagerReport from './components/StongegateReport-Component/ManagerReport';*/
import UserDeviceProvider from './components/DeviceInfo';
import { FeatureFlagProvider } from './FeatureFlagContext';


const App = () => {
    const location = useLocation();
    const path = location.pathname;
    return (
        <UserDeviceProvider>

            {/*<Routes>*/}
            {/*    <Route path="/ManagerReport/:email/:guid" element={<ManagerReport />}></Route>*/}
            {/*</Routes>*/}

            <FeatureFlagProvider>
                <AccessGroupProvider>
                    {!path.startsWith('/ManagerReport') && (
                        <Layout>
                            <Routes>
                                {AppRoutes.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                        </Layout>
                    )}
                </AccessGroupProvider>
            </FeatureFlagProvider>

        </UserDeviceProvider>
    );
};

export default App;
