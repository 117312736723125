import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './Nav-Component/NavMenu';


export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = {
            isDark: false // A default value until your API data loads
        };
    }

    componentDidMount() {
        // If your API call is done in the parent component and the data is passed as a prop
        if (this.props.defaultIsDark !== undefined) {
            this.setState({ isDark: this.props.defaultIsDark });
        }
    }
    handleDarkModeChange = (isDarkFromChild) => {
        // Now you have the data from the child here, and you can set the state with it
        this.setState({ isDark: isDarkFromChild });
    };

    handleOnClick = () => {
        this.setState(prevState => ({
            isDark: !prevState.isDark
        }));
    };

    render() {
        const { isDark } = this.state;
        // Logic to determine if the theme class should be applied
        const shouldApplyThemeClass = !window.location.href.includes('manager-report');
        const themeClass = shouldApplyThemeClass ? (isDark ? "theme--dark" : "theme--light") : '';
        return (
            <div className={themeClass}>
                <NavMenu ontoggle={this.handleOnClick} PasstoLayout={this.handleDarkModeChange} isDark={isDark} />
                <Container fluid tag="main">
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
